import React from "react";
import { Box, Typography } from "@mui/material";

const Festival = () => {
  return (
    <>
      <Typography variant="h1" align="center">
        Festival
      </Typography>
      <Box>
        <Typography variant="h4" align="center" bgcolor={"yellow"}>
          Page en cours de construction
        </Typography>
      </Box>
    </>
  );
};

export default Festival;
